const stations = [
  {
    name: "Azeitão - S. D. do Azeitão",
    address: "BR 230 - Km 215 São Domingos do Azeitão - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/azeitao-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/azeitao-logo.png",
    lat: -6.8300388,
    long: -44.6644259,
  },
  {
    name: "Canaã - S. D. do Azeitão",
    address: "BR 230 - Km 09 São Domingos do Azeitão - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/canaa-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/canaa-logo.png",
    lat: -6.7777937,
    long: -44.6124768,
  },
  {
    name: "CoopDiesel - N. Sra. da Penha",
    address: "Rua Guanabara, 634, Araguari - MG",
    state: "Minas Gerais",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/coopdiesel-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/coopdiesel-bigpicture.png",
    lat: -18.6699933,
    long: -48.180095,
  },
  {
    name: "HD 11 - Lagoa do Piauí",
    address: "Av José Soares da Silva Nº659, Lagoa do Piauí - PI",
    state: "Piauí",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/hd-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/hd-logo.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "Mais - Aliança",
    address: "BR 135 - Km 320, nº 02 - Dom Pedro - MA",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/mais-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/mais-logo.png",
    state: "Maranhão",
    lat: 0.0,
    long: 0.0,
  },

  {
    name: "Maracanã - São Luís",
    address: "BR 135 - Km 06 São Luís - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/maracana-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/maracana-logo.png",
    lat: -2.6342372,
    long: -44.2669586,
  },
  {
    name: "Nordestão - São Luís",
    address:
      "Av. Eng. Emiliano Macieira, nº 55 Km 14,5 - Pedrinhas - São Luís - MA",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/nordestao-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/nordestao-logo.png",
    state: "Maranhão",
    lat: -2.6220322,
    long: -44.3176479,
  },
  {
    name: "Petrosoja - Balsas",
    address: "Av. Gov. Luiz Rocha, nº 30 - Potosi Balsas - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/petrosoja-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/petrosoja-logo.png",
    lat: -7.5231968,
    long: -46.0403221,
  },
  {
    name: "Posto Carvalho Biné",
    address: "Rod BR 226/100 - Presidente Dutra - MA, 65760-000",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/carvalho-bin%C3%A9-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/carvalho-bin%C3%A9-bigpicture.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "Posto Rede K",
    address: "Avenida Bernardo Sayão, 566 - Setor Oeste - Araguaína - TO",
    state: "Tocantins",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/redek-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/redek-bigpicture.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "Posto Vila Nova",
    address: "Rua Benedito Leite, 956, Jardim São Luís Imperatriz - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/vila-nova-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/vila-nova-bigpicture.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "Premier",
    address:
      "Av. Engenheiro Emiliano Macieira, 25, Pedrinhas - São Luís - MA, 65095-603",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/premier-picture.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/premier-big-picture.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "Recreio 2 - Bacabeira",
    address: "BR 135 - Km 48 - Peri de Cima - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/recreio-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/recreio-logo.png",
    lat: -4.2802736,
    long: -44.4223441,
  },
  {
    name: "Roda Viva Matriz",
    address:
      "Rod. BR 010, 0, KM 1672 C PA 256, Interior - Paragominas - PA, 68625-295",
    state: "Pará",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/roda-viva-picture.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/roda-viva-big-picture.png",
    lat: 0.0,
    long: 0.0,
  },
  {
    name: "São Cristóvão - Balsas",
    address: "Av. Gov. Luiz Rocha, S/n - Potosi Balsas - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/sao-cristovao-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/sao-cristovao-logo.png",
    lat: -7.5167006,
    long: -46.0352095,
  },
  {
    name: "Triunfo II - S. D. do Azeitão",
    address: "BR 230 - Km 210 São Domingos do Azeitão - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/triunfo-ii-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/triunfo-ii-logo.png",
    lat: -6.8147082,
    long: -44.6150436,
  },
  {
    name: "Valen - São Luís",
    address: "Av. Eng. Emiliano Macieira, Km 02 São Luís - MA",
    state: "Maranhão",
    pictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/pictures/valen-logo.png",
    bigPictureUrl:
      "https://towner-stations.s3.amazonaws.com/master/big-pictures/valen-logo.png",
    lat: -2.6492686,
    long: -44.3066197,
  },
]

export default stations
