import React from "react"
import { TypographySmallTitle } from "./Typography"
import { Slide } from "react-slideshow-image"
import stations from "./Stations"
import "react-slideshow-image/dist/styles.css"

const PartnersSection = () => {
  const properties = {
    duration: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    indicators: true,
    responsive: [
      { breakpoint: 640, settings: { slidesToShow: 3, slidesToScroll: 2 } },
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
    ],
  }

  const imageStyle = {
    maxWidth: "150px",
    height: "auto",
  }

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "64px",
        marginBottom: "64px",
      }}
    >
      <TypographySmallTitle black>Nossos Parceiros</TypographySmallTitle>
      <Slide {...properties}>
        {stations.map(station => {
          return <img src={station["bigPictureUrl"]} style={imageStyle} />
        })}
      </Slide>
    </div>
  )
}

export default PartnersSection
