import styled, { css } from 'styled-components';

type SectionProps = {
  imgUrl?: string,
}

const Section = styled.section<SectionProps>`
  position: relative;
  height: calc(100vh - 60px);
  background-color: #fff;
  ${(props) => props.imgUrl && css<SectionProps>`
    background-image: url(${(imgprops) => imgprops.imgUrl});
    background-size: cover;
  `}
  @media(max-width: 800px){
    height: fit-content;
  }
`;

export default Section;
