import styled from "styled-components"

const Button = styled.a`
  padding: 22px 40px;
  display: inline-block;
  cursor: pointer;
  border-radius: 80px;
  background: linear-gradient(90deg, #ff3951 -5%, #ff7950 95%);
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  margin-top: 32px;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    font-size: 12px;
    padding: 16px 32px;
  }
`

export default Button
